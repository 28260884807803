/* src/components/terms/Terms.css */
.terms {
    padding: 20px;
    background-color: #f4f4f4;
    color: #333;
    font-family: Arial, sans-serif;
}

.terms h1 {
    color: #333;
}

.terms h2 {
    color: #555;
}

.terms p {
    font-size: 16px;
    color: #666;
    line-height: 1.6;
}

.terms ul {
    margin: 10px 0;
    padding-left: 20px;
}

.terms li {
    margin-bottom: 5px;
    font-size: 16px;
    line-height: 1.6;
}
