/* src/privacy.css */
.accordion {
    background-color: #eee;
    color: #444;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
    transition: 0.4s;
  }
  
  .accordion:hover, .accordion.active {
    background-color: #ccc;
  }
  
  .panel {
    padding: 0 18px;
    background-color: white;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
  }
  
  .panel.active {
    max-height: 100vh; /* Adjust max-height for longer content */
  }
  